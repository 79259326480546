<template>
	<v-container fill-height fluid>
		<v-row justify="center" no-gutters>
			<v-col cols="12" sm="11" md="9" lg="8">
				<v-carousel
					height="auto"
					interval="4000"
					continuous
					cycle1
					show-arrows-on-hover
				>
					<v-carousel-item
						v-for="(item, i) in $store.state.home.slides"
						:key="i"
						:src="item.src"
					>
						<template v-slot:default>
							<!-- <img :src="item.src" class="techimg" :alt="item.title" /> -->
							<v-img width="100%" aspect-ratio="1.4" :src="item.src"></v-img>
						</template>
					</v-carousel-item>
				</v-carousel>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
	name: "home",
	components: {
		// HelloWorld
	},
	data() {
		return {
			items: [{ title: "", src: "" }],
			colors: ["primary", "secondary", "yellow darken-2", "red", "orange"]
		};
	}
};
</script>
<style>
.techimg {
	object-fit: cover;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
}
</style>

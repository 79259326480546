import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		title: "Jesse Kramer Photography",
		phone: "072-906-8557",
		email: "info@jessekramerphotography.com",
		version: "1.0.1",
		pages: [
			{ href: "productions", title: "Productions", icon: "mdi-spotlight" },
			{ href: "rehearsals", title: "Rehearsals", icon: "mdi-drama-masks" },
			{ href: "headshots", title: "Headshots", icon: "mdi-face" },
			{ href: "promotional", title: "Promotional", icon: "mdi-star" },
			{ href: "about", title: "About", icon: "mdi-help" },
			{ href: "contact", title: "Contact", icon: "mdi-contact-mail" }
		],
		home: {
			slides: [
				{ title: "Equus", src: "/img/gallery/home/01 Equus.jpg" },
				{ title: "Kinky Boots", src: "/img/gallery/home/02 Kinky Boots.jpg" },
				{ title: "Orphee", src: "/img/gallery/home/03 Orphee.jpg" },
				{ title: "Kamphoer", src: "/img/gallery/home/04 Kamphoer.jpg" },
				{
					title: "Rocky Horror Picture Show",
					src: "/img/gallery/home/04 Rocky.jpg"
				},
				{ title: "Cabaret", src: "/img/gallery/home/05 Cabaret.jpg" },
				{ title: "Kinky Boots", src: "/img/gallery/home/05 Kinky Boots.jpg" },
				{
					title: "The Curious Incident of the Dog in the Nighttime",
					src: "/img/gallery/home/06 The Curious Incident.jpg"
				},
				{ title: "Oedipus Rex", src: "/img/gallery/home/07 Oedipus Rex.jpg" },
				{
					title: "Into the Woods",
					src: "/img/gallery/home/08 Into the Woods.jpg"
				},
				{ title: "Danger in the Dark", src: "/img/gallery/home/09 Danger.jpg" },
				{ title: "Spring Awakening", src: "/img/gallery/home/10 Spring.jpg" },
				{ title: "West Side Story", src: "/img/gallery/home/11 West Side.jpg" }
				// { title: "Equus", src: "/img/gallery/slides/EquusOnline-753.jpg" },
				// {
				// 	title: "Danger in the Dark",
				// 	src: "/img/gallery/slides/Danger Prod Online JKK-2435.jpg"
				// },
				// {
				// 	title: "Kinky Boots",
				// 	src: "/img/gallery/slides/KB Press Online JK-15.jpg"
				// },
				// {
				// 	title: "Kinky Boots",
				// 	src: "/img/gallery/slides/KB Press Online JK-18.jpg"
				// },
				// {
				// 	title: "Kinky Boots",
				// 	src: "/img/gallery/slides/KB Press Online JK-20.jpg"
				// },
				// {
				// 	title: "Macbeth",
				// 	src: "/img/gallery/slides/Macbeth Online JKK-5686.jpg"
				// }
			]
		},
		productions: {
			gallery: [
				{
					title: "Rocky Horror Picture Show",
					src: "/img/gallery/productions/01 Rocky Horror.jpg"
				},
				{
					title: "West Side Story",
					src: "/img/gallery/productions/02 West Side Story.jpg"
				},
				{
					title: "West Side Story",
					src: "/img/gallery/productions/03 West Side Story.jpg"
				},
				{
					title: "Kinky Boots",
					src: "/img/gallery/productions/04 Kinky Boots.jpg"
				},
				{
					title: "Rocky Horror Picture Show",
					src: "/img/gallery/productions/05 Rocky Horror.jpg"
				},
				{
					title: "Kinky Boots",
					src: "/img/gallery/productions/06 Kinky Boots.jpg"
				},
				{
					title: "Danger in the Dark",
					src: "/img/gallery/productions/07 Danger in the Dark.jpg"
				},
				{
					title: "Funny Girl",
					src: "/img/gallery/productions/07 Funny Girl.jpg"
				},
				{
					title: "Funny Girl",
					src: "/img/gallery/productions/08 Funny Girl.jpg"
				},
				{
					title: "West Side Story",
					src: "/img/gallery/productions/09 West Side Story.jpg"
				},
				{
					title: "Spring Awakening",
					src: "/img/gallery/productions/10 Spring Awakening.jpg"
				},
				{
					title: "Into the Woods",
					src: "/img/gallery/productions/11 Into the Woods.jpg"
				},
				{
					title: "West Side Story",
					src: "/img/gallery/productions/13 West Side Story.jpg"
				},
				{ title: "Macbeth", src: "/img/gallery/productions/14 Macbeth.jpg" },
				{
					title: "King Kong",
					src: "/img/gallery/productions/15 King Kong.jpg"
				},
				{
					title: "King Kong",
					src: "/img/gallery/productions/16 King Kong.jpg"
				},
				{ title: "Macbeth", src: "/img/gallery/productions/17 Macbeth.jpg" },
				{ title: "Kamphoer", src: "/img/gallery/productions/18 Kamphoer.jpg" },
				{
					title: "Funny Girl",
					src: "/img/gallery/productions/19 Funny Girl.jpg"
				},
				{
					title: "King Kong",
					src: "/img/gallery/productions/20 King Kong.jpg"
				},
				{
					title: "Kinky Boots",
					src: "/img/gallery/productions/21 Kinky Boots.jpg"
				},
				{
					title: "King Kong",
					src: "/img/gallery/productions/22 King Kong.jpg"
				},
				{
					title: "West Side Story",
					src: "/img/gallery/productions/23 West Side Story.jpg"
				},
				{
					title: "West Side Story",
					src: "/img/gallery/productions/24 West Side Story.jpg"
				},
				{ title: "Equus", src: "/img/gallery/productions/25 Equus.jpg" },
				{ title: "Tswalo", src: "/img/gallery/productions/26 Tswalo.jpg" },
				{ title: "Cabaret", src: "/img/gallery/productions/27 Cabaret.jpg" },
				{
					title: "The Curious Incident",
					src: "/img/gallery/productions/28 The Curious Incident.jpg"
				}
				// {
				// 	title: "Test",
				// 	src: "/img/gallery/productions/JKK website gallery productions.png"
				// }
			]
		},
		rehearsals: {
			gallery: [
				{
					title: "Danger in the Dark",
					src: "/img/gallery/rehearsals/01 Danger in the Dark.jpg"
				},
				{ title: "Evita", src: "/img/gallery/rehearsals/02 Evita.jpg" },
				{
					title: "Spring Awakening",
					src: "/img/gallery/rehearsals/03 Spring Awakening.jpg"
				},
				{
					title: "Don Pasquale",
					src: "/img/gallery/rehearsals/04 Don Pasquale.jpg"
				},
				{
					title: "Capuleti E I Montechi",
					src: "/img/gallery/rehearsals/05 Capuleti E I Montechi.jpg"
				},
				{ title: "--", src: "/img/gallery/rehearsals/06.jpg" },
				{
					title: "Spring Awakening",
					src: "/img/gallery/rehearsals/07 Spring Awakening.jpg"
				},
				{
					title: "Cape Town Opera",
					src: "/img/gallery/rehearsals/08 Cape Town Opera.jpg"
				},
				{ title: "Langarm", src: "/img/gallery/rehearsals/09 Langarm.jpg" },
				{
					title: "Spring Awakening",
					src: "/img/gallery/rehearsals/10 Spring Awakening.jpg"
				},
				{ title: "Evita", src: "/img/gallery/rehearsals/11 Evita.jpg" },
				{ title: "Tsotsi", src: "/img/gallery/rehearsals/12 Tsotsi.jpg" },
				{ title: "Tsotsi", src: "/img/gallery/rehearsals/13 Tsotsi.jpg" },
				{ title: "Evita", src: "/img/gallery/rehearsals/14 Evita.jpg" },
				{
					title: "Cape Town Opera",
					src: "/img/gallery/rehearsals/15 Cape Town Opera.jpg"
				},
				{
					title: "Maria Stuarda",
					src: "/img/gallery/rehearsals/16 Maria Stuarda.jpg"
				},
				{
					title: "Don Pasquale",
					src: "/img/gallery/rehearsals/17 Don Pasquale.jpg"
				},
				{ title: "Tiger Bay", src: "/img/gallery/rehearsals/18 Tiger Bay.jpg" },
				{ title: "Tsotsi", src: "/img/gallery/rehearsals/19 Tsotsi.jpg" },
				{
					title: "The Native who caused all the trouble",
					src:
						"/img/gallery/rehearsals/20 The Native who caused all the trouble.jpg"
				},
				{ title: "Tiger Bay", src: "/img/gallery/rehearsals/21 Tiger Bay.jpg" },
				{
					title: "Orphee de Eurydice",
					src: "/img/gallery/rehearsals/22 Orphee de Eurydice.jpg"
				},
				{
					title: "Richard III",
					src: "/img/gallery/rehearsals/23 Richard III.jpg"
				},
				{
					title: "The Taming of the Shrew",
					src: "/img/gallery/rehearsals/24 The Taming of the Shrew.jpg"
				},
				{
					title: "West Side Story",
					src: "/img/gallery/rehearsals/25 West Side Story.jpg"
				},
				{ title: "Evita", src: "/img/gallery/rehearsals/26 Evita.jpg" },
				{
					title: "Spring Awakening",
					src: "/img/gallery/rehearsals/27 Spring Awakening.jpg"
				},
				{
					title: "West Side Story",
					src: "/img/gallery/rehearsals/28 West Side Story.jpg"
				}
			]
		},
		headshots: {
			gallery: [
				{
					title: "Shannon",
					src: "/img/gallery/headshots/headshot_Shannon.jpg"
				},
				{ title: "Genna", src: "/img/gallery/headshots/headshot_Genna.jpg" },
				{ title: "Ryno", src: "/img/gallery/headshots/headshot_Ryno.jpg" },
				{ title: "Naledi", src: "/img/gallery/headshots/headshot_Naledi.jpg" },
				{ title: "Kayley", src: "/img/gallery/headshots/headshot_Kayley.jpg" },
				{
					title: "Terrence",
					src: "/img/gallery/headshots/headshot_Terrence.jpg"
				}
			]
		}
	},
	mutations: {},
	actions: {},
	modules: {}
});

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "home",
		// meta: { title: "Home" },
		component: Home
	},
	{
		path: "/productions",
		name: "productions",
		meta: { title: "Productions" },
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Productions.vue")
	},
	{
		path: "/rehearsals",
		name: "rehearsals",
		meta: { title: "Rehearsals" },
		component: () => import("../views/Rehearsals.vue")
	},
	{
		path: "/headshots",
		name: "headshots",
		meta: { title: "Headshots" },
		component: () => import("../views/Headshots.vue")
	},
	{
		path: "/promotional",
		name: "promotional",
		meta: { title: "Promotional" },
		component: () => import("../views/Promotional.vue")
	},
	{
		path: "/about",
		name: "about",
		meta: { title: "About" },
		component: () => import("../views/About.vue")
	},
	{
		path: "/contact",
		name: "contact",
		meta: { title: "Contact" },
		component: () => import("../views/Contact.vue")
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

export default router;

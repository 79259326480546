import Vue from "vue";
// import App from "./App.vue";
import App from "./App.vue";
import store from "./store";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import router from "./router";
import "./assets/styles/default.css";

Vue.config.productionTip = false;

new Vue({
	store,
	vuetify,
	router,
	render: h => h(App)
}).$mount("#app");

<template>
	<v-app :class="'bp-' + $vuetify.breakpoint.name">
		<v-navigation-drawer clipped v-model="drawer" app class="text-uppercase ">
			<v-list dense>
				<v-list-item link to="/">
					<v-list-item-action>
						<v-icon>mdi-home</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title class="font-weight-light"
							>Home</v-list-item-title
						>
					</v-list-item-content>
				</v-list-item>

				<v-list-item
					link
					:to="'/' + page.href"
					v-for="page in $store.state.pages"
					:key="'item-' + page.href"
				>
					<v-list-item-action>
						<v-icon>{{ page.icon }}</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title class="font-weight-light">{{
							page.title
						}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-divider></v-divider>
			<div class="pa-5 overline" style="font-size:0.55rem !important;">
				<b>{{ $store.state.title }}</b
				><br />
				{{ $store.state.phone }}<br />
				{{ $store.state.email }}<br />
				v{{ $store.state.version }}<br />&copy;2019<br />
			</div>
		</v-navigation-drawer>

		<v-app-bar app color="white" class="hidden-sm-and-up">
			<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<router-link class="header-link" to="/" title="Home">
				<v-toolbar-title class="header-text" style="font-size:1.1rem;">{{
					$store.state.title
				}}</v-toolbar-title>
			</router-link>
		</v-app-bar>
		<v-app-bar
			app
			color="white"
			dense
			class="text-center d-none d-sm-block text-center header"
			elevate-on-scroll
		>
			<v-toolbar-title class="header-text mx-auto d-md-none">
				<router-link to="/">{{ $store.state.title }}</router-link>
			</v-toolbar-title>
			<v-toolbar-title
				class="header-text mx-auto d-none d-md-block"
				style="font-size:3rem"
			>
				<router-link style="font-size:3rem" to="/" title="Home">{{
					$store.state.title
				}}</router-link>
			</v-toolbar-title>

			<template v-slot:extension>
				<h3 class="mx-auto pa-0">
					<router-link
						class="mx-2 header-link"
						v-for="page in $store.state.pages"
						:key="'item-' + page.href"
						:to="'/' + page.href"
						>{{ page.title }}</router-link
					>
				</h3>
			</template>
		</v-app-bar>

		<v-content id="main" class="maincontent pt-12 mt-0 mt-sm-0 pb-12">
			<router-view />
		</v-content>

		<v-footer
			color="rgba(255,255,255,0.5)"
			style="text-shadow:0px 0px 1px white;"
			padless
			fixed
			app
			class="text-center footer"
		>
			<v-col class="pa-1 text-center hidden-sm-and-up" cols="12">
				<span>
					<v-icon small>mdi-cellphone-iphone</v-icon
					>{{ $store.state.phone }} </span
				><br />
				<span> <v-icon small>mdi-email</v-icon>{{ $store.state.email }} </span
				><br />
				&copy; 2019
			</v-col>

			<v-col class="pa-1 text-center d-none d-sm-block" cols="12">
				&copy; 2019 |
				<span>
					<v-icon small>mdi-cellphone-iphone</v-icon>{{ $store.state.phone }}
				</span>
				|
				<span> <v-icon small>mdi-email</v-icon>{{ $store.state.email }} </span>
			</v-col>
		</v-footer>
	</v-app>
</template>

<script>
// import HelloWorld from "./components/HelloWorld";

export default {
	name: "App",

	components: {
		// HelloWorld
	},
	// updated() {
	// 	console.log(this.$route);

	// },
	watch: {
		$route(to) {
			document.title =
				"Jesse Kramer Photography | " +
				(to.meta.title || "Cape Town | Theatre & Production | Head Shots");
			//console.warn("DESCR:", document.description);
		}
	},

	data: () => ({
		drawer: false
	})
};
</script>
<style>
.header-link {
	color: black !important;
	text-decoration: none;
}
.header-link:hover {
	text-shadow: 0px 0px 5px #999;
	text-decoration: underline;
}
</style>
